<template>
        <div class="modal top" :class="{active: modal}">
            <div class="modal-card not-interested">
                <div class="modal-body">
                    <div class="checkbox-container">
                        <p class="title">{{$t('matches.detail.person.modal.notInterested.notInterestedReasonsTitle')}}</p>
                        
                        <div class="checks">
                            <div class="input-container noselect hover-pointer" @click="reasons.classification = !reasons.classification">
                                <p class="label">{{$t(`matches.detail.person.modal.notInterested.reasons.classification`)}}</p>
                                <input type="checkbox" v-model="reasons.classification">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.workDays = !reasons.workDays">
                                <p class="label">{{$t(`matches.detail.person.modal.notInterested.reasons.workDays.${type}`)}}</p>
                                <input type="checkbox" v-model="reasons.workDays">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.workTimes = !reasons.workTimes">
                                <p class="label">{{$t(`matches.detail.person.modal.notInterested.reasons.workTimes.${type}`)}}</p>
                                <input type="checkbox" v-model="reasons.workTimes">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.hoursPerWeekTooHigh = !reasons.hoursPerWeekTooHigh">
                                <p class="label">{{$t('matches.detail.person.modal.notInterested.reasons.hoursPerWeekTooHigh')}}</p>
                                <input type="checkbox" v-model="reasons.hoursPerWeekTooHigh">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.hoursPerWeekTooLow = !reasons.hoursPerWeekTooLow">
                                <p class="label">{{$t('matches.detail.person.modal.notInterested.reasons.hoursPerWeekTooLow')}}</p>
                                <input type="checkbox" v-model="reasons.hoursPerWeekTooLow">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.workLocation = !reasons.workLocation">
                                <p class="label">{{$t('matches.detail.person.modal.notInterested.reasons.workLocation')}}</p>
                                <input type="checkbox" v-model="reasons.workLocation">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.distance = !reasons.distance">
                                <p class="label">{{$t(`matches.detail.person.modal.notInterested.reasons.distance.${type}`)}}</p>
                                <input type="checkbox" v-model="reasons.distance">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.salary = !reasons.salary">
                                <p class="label">{{$t(`matches.detail.person.modal.notInterested.reasons.salary`)}}</p>
                                <input type="checkbox" v-model="reasons.salary">
                            </div>

                            <div class="input-container noselect hover-pointer" @click="reasons.employer = !reasons.employer"> 
                                <p class="label">{{$t(`matches.detail.person.modal.notInterested.reasons.employer.${type}`)}}</p>
                                <input type="checkbox" v-model="reasons.employer">
                            </div>

                            <div class="input-container other">
                                <p class="label">{{$t('matches.detail.person.modal.notInterested.reasons.other')}}</p>
                                <textarea class="text-area" v-model="other" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cta-container">
                    <div class="cta cta-secondary cta-slim" :class="{disabled: !formValidated, 'loading dark': loading}" @click="imNotInterested()">{{$t('language.send')}}</div>
                    <div class="cta cta-outline dark cta-slim" @click="close()">{{$t('language.cancel')}}</div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'MatchDetailImNotInterested',

    props: {
        type: {
            type: String,
            default: null
        },

        show: {
            type: Boolean,
            default: false
        },

        parentMatchId: {
            type: String,
            default: ''
        },

        source: {
            type: String,
            default: ''
        }
    },

    watch: {
        show: function(val) {
            this.modal = val;
        },

        reasons: {
            deep: true,
            handler() {
                const self = this;
                let valid = false;

                Object.keys(self.reasons).map(key => {
                    if(self.reasons[key]) {
                        valid = true;
                    }
                })

                if(self.other) {
                    valid = true;
                }

                if(valid) {
                    self.formValidated = true;
                }else {
                    self.formValidated = false;
                }
            }
        },

        other: function() {
            if(this.other) {
                this.formValidated = true;
            }else {
                this.formValidated = false;
                Object.keys(this.reasons).map(key => {
                    if(this.reasons[key]) {
                        this.formValidated = true;
                    }
                })
            }
        },

        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        }
    },

    data() {
        return {
            modal: false,
            formValidated: false,
            loading: false,

            reasons: {
                classification: false,
                workDays: false,
                workTimes: false,
                hoursPerWeekTooHigh: false,
                hoursPerWeekTooLow: false,
                workLocation: false,
                distance: false,
                salary: false,
                employer: false
            },

            other: ""
        }
    },

    methods: {
        imNotInterested: function() {
            const self = this; 

            if(self.formValidated) {
                self.loading = true;

                const personNotInterestedReasons = [];
                Object.keys(self.reasons).map(key => {
                    if(self.reasons[key]) {
                        personNotInterestedReasons.push(key);
                    }
                })

                const data = {
                    stage: 'notInterested',
                    parentMatchId: self.parentMatchId,
                    personNotInterestedReasons: {
                        reasons: personNotInterestedReasons,
                        other: self.other
                    }
                }

                self.$axios
                    .post(`match/${self.type}/stage`, data)
                    .then(response => {
                        if(response.status == 200) {
                            self.reset();

                            if(self.source === 'swipe') {
                                self.$emit('close', { next: true, source: 'not-interested' });
                            }else {
                                self.$utils._navigate_to_name(`${self.type}-matches`);
                            }
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        reset: function() {
            const self = this;

            self.modal = false;
            self.formValidated = false;
            self.loading = false;

            self.other = '';
            Object.keys(self.reasons).forEach(key => {
                self.reasons[key] = false;
            })
        },

        close: function() {
            const self = this;

            self.reset();
            self.$emit('close', {next: false, source: 'not-interested'});
        }
    }
}
</script>

