<template>
    <div class="modal top" :class="{active: modal}">
        <div class="modal-card interested">
            <div class="modal-body">
                <div class="state-container">
                    <p class="title">{{$t('matches.detail.person.modal.interested.locations.title')}}</p>
                    <p class="subtitle">{{$t('matches.detail.person.modal.interested.locations.subtitle')}}</p>

                    <div :class="['location-container', error('selectedMatches')]">
                        <div :class="['location', { active: data.selectedMatches.includes(match._id) }]" v-for="match in filteredMatches" :key="match._id" @click="select_match(match._id)">
                            <div class="text">
                                <span>
                                    <font-awesome-icon :class="{ active: data.selectedMatches.includes(match._id) }" :icon="data.selectedMatches.includes(match._id) ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                    <p class="address" v-html="getFormattedAddressForMatch(match)"></p>
                                </span>
                                
                                <p class="percentage" v-html="$utils._computePercentageHTML(match.matchPercentage)"></p>
                            </div>
                        </div>
                    </div>  

                    <FormError :data="validation.selectedMatches" />
                </div>
            </div>

            <div class="cta-container">
                <div class="cta cta-secondary cta-slim" :class="{'loading dark': loading}" @click="im_interested()">{{$t('matches.detail.person.modal.interested.imStillInterested')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="close()">{{$t('language.words.neverMind')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: 'WorkProviderMatchDetailImInterested',

    components: { FormError },

    props: {
        id: {
            type: String,
            default() {
                return ''
            }
        },

        type: {
            type: String,
            default: null
        },

        show: {
            type: Boolean,
            default: false
        },

        matches: {
            type: Array,
            default() {
                return []
            }
        },

        workProviderOfferLocations: {
            type: Array,
            default() {
                return []
            }
        },

        source: {
            type: String,
            default: ''
        },
    },

    watch: {
        show: function(val) {
            this.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        'data.selectedMatches': function() {
            const self = this;

            if(self.data.selectedMatches.length !== 0 && self.validation.selectedMatches.error === true) {
                self.validation.selectedMatches.error = false;
            }
        }
    },

    data() {
        return {
            modal: false,
            loading: false,
            formValidated: false,

            data: {
                selectedMatches: []
            },

            validation: {
                selectedMatches: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },
        }
    },

    computed: {
        filteredMatches: function() {
            const self = this;
            return self.matches.filter(match => !match.deleted && match.deletionReason !== 'workProviderOfferLocationsChanged').sort(match => match.workLocation === 'regular').sort(match => match.distance);
        }
    },

    methods: {
        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            self.validation.selectedMatches.error = false;

            if(self.data.selectedMatches.length === 0) {
                self.validation.selectedMatches.error = true;
                self.formValidated = false;
            }
        },

        im_interested: function() {
            const self = this; 

            self.validate_mandatory_fields();
            if(self.formValidated) { 
                self.loading = true;

                const data = {
                    stage: 'interested',
                    selectedMatches: self.data.selectedMatches
                }

                self.$axios
                    .post(`matches/${self.type}/stage`, data)
                    .then(response => {
                        if(response.status == 200) {
                            if(self.source === 'swipe') {
                                self.reset();
                                self.$emit('close', { next: true, source: 'interested' });
                            }else {
                                self.$utils._navigate_to_name(`${self.type}-matches`);
                            }
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        select_match: function(id) {
            const self = this;

            if(self.data.selectedMatches.includes(id)) {
                if(self.data.selectedMatches.indexOf(id) !== -1)
                    self.data.selectedMatches.splice(self.data.selectedMatches.indexOf(id), 1);
            }else {
                self.data.selectedMatches.push(id)
            }
        },

        getFormattedAddressForMatch: function(match) {
            const self = this;
            
            if(match && match.workLocation === 'regular' && match.workProviderOfferLocationId) {
                return `${self.workProviderOfferLocations.find(location => location._id.toString() === match.workProviderOfferLocationId.toString()).formattedAddress} - <b>${match.distance}km</b>`
            }else if (match && match.workLocation === 'remote') {
                return self.$t('matches.workLocations.remote')
            }
        },

        reset: function() {
            const self = this;

            self.modal = false;
            self.loading = false;
            self.formValidated = false;

            self.data.selectedMatches = [];
            self.validation.selectedMatches.error = false;
        },

        close: function() {
            const self = this;

            self.reset();
            self.$emit('close', { next: false, source: 'interested' });
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    }
}
</script>

