<template>
    <div class="worker-match-cards swipe">
        <div v-if="loaded">
            <div v-if="data.length !== 0">
                <div v-for="(match, index) in data" :key="match._id" :class="['card card-worker-match card-swipe', { 'active': index === current_index }]" @click="open_modal(match)">
                    <span v-if="match.globalStage === 'seen'" class="stage-disclaimer seen"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{ $t('matches.swipe.seen') }}</span>
                    <span v-if="match.globalStage === 'new'" class="stage-disclaimer new"><font-awesome-icon :icon="['fa', 'circle-check']" />{{ $t('matches.swipe.new') }}</span>
                    
                    <div class="header-container">
                        <div class="left">
                            <img v-if="match.logo_url && match.logo_url !== 'none'" :src='match.logo_url' alt="company logo">
                            <p class="no-logo-label" v-else>{{ match.workProviderAlternateName }}</p>
                        </div>
                    </div>

                    <div class="job-title-salary-container flex flex-row">
                        <p class="job-title">{{ match.offerName }}</p>
                        <p class="salaries">{{ format_salaries(match.salaries) }}</p>
                    </div>
                    
                    <div class="work-location-container">
                        <p class="address">{{ match.address }}</p>
                        <div class="row">
                            <p class="work-location">{{ match.location }}</p>
                            <p class="distance" v-html="match.distances"></p>
                        </div>
                    </div>

                    <div class="schedule-container">
                        <div class="day-time-container" v-for="time in match.formatted_work_times" :key="time.day">
                            <span class="day-bubble bubble">{{ format_days(time.days) }}</span>
                            <span class="time-bubble bubble">{{ `${format_time(time.times.start)} - ${format_time(time.times.end)}` }}</span>
                        </div>
                    </div>

                    <div class="hours-container">
                        <div :class="[`row ${match.schedule_type}`]">
                            <span v-if="match.hours_per_week_persons_choice" v-html="$t('matches.swipe.hours.hours_per_week_persons_choice')"></span>
                            <span v-else class="bubble"><b>{{ match.hoursPerWeek }}h</b> {{ $t('language.per_week') }}</span>
                            
                            <p v-if="!match.hours_per_week_persons_choice" class="schedule-type">{{ $t(`matches.swipe.hours.schedule_type.${match.schedule_type}`) }}</p>
                        </div>
                    </div>

                    <div class="info-container">
                        <p class="start-date">{{ $t('matches.swipe.start-date', {date: format_start_date(match.startDate)}) }}</p>
                        <p class="added-date">{{ match.work_provider_offer_formatted_id }}</p>
                    </div>
                </div>

                <div v-if="current_index === data.length" class="card empty-card">
                    <p class="title" v-html="$t(`matches.cards.worker_matches.empty.end`)"></p>
                </div>
            </div>
            
            <div v-else>
                <div class="card empty-card">
                    <p class="title" v-html="$t(`matches.cards.worker_matches.empty.end`)"></p>
                </div>
            </div>

            <div :class="['bottom', { disabled: data.length === 0 }]">
                <p v-if="data.length !== 0" class="disclaimer" v-html="$t('matches.swipe.bottom.dislcaimer')"></p>

                <div class="row">
                    <span class="circle not-interested" @click="im_not_interested()"><font-awesome-icon :icon="['fa', 'xmark']" /></span>
                    <span :class="['later noselect', { 'cta loading': im_interested_loading }]" @click="next('later')">{{ $t('matches.swipe.bottom.later') }}</span>
                    <span class="circle interested" @click="im_interested()"><font-awesome-icon :icon="['fa', 'heart']" /></span>
                </div>
            </div>
        </div>
        
        <div v-else class="card loading"></div>

        <div v-if="additional_info_modal.modal && additional_info_modal.data" class="modal match-swipe-additional-data" :class="{ active: additional_info_modal.modal }">
            <div class="modal-card">
                <div class="title-container">
                    <p class="title">{{$t('matches.swipe.modal.title')}}</p>
                    <font-awesome-icon @click="additional_info_modal.modal = false" :icon="['fa', 'square-xmark']" />
                </div>

                <div class="modal-body">
                    <div class="section">
                        <p class="title">{{$t('matches.swipe.modal.sections.employer.title')}}</p>
                        <p class="employer">{{ additional_info_modal.data.workProviderAlternateName }}</p>
                    </div>

                    <div class="section">
                        <p class="title">{{$t('matches.swipe.modal.sections.benefits.title')}}</p>

                        <div class="benefits">
                            <span v-for="choice in additional_info_modal.data.benefits.choices" :key="choice"><font-awesome-icon :icon="['fa', 'circle-check']" />{{ $t(`benefits.${choice}`) }}</span>
                            <span v-if="additional_info_modal.data.benefits.choices.length === 0 && !additional_info_modal.data.benefits.other" class="empty"><font-awesome-icon :icon="['fa', 'circle-check']" />{{ $t('matches.swipe.modal.sections.benefits.empty') }}</span>
                            
                            <span v-if="additional_info_modal.data.benefits.other" :class="['title-other', { empty: additional_info_modal.data.benefits.choices.length === 0 }]">{{ $t('language.words.other') }}</span>
                            <span v-if="additional_info_modal.data.benefits.other" class="other">{{ additional_info_modal.data.benefits.other }}</span>
                        </div>
                    </div>
                        
                    <div class="section fheight">
                        <div class="map" v-if="filter_addresses(additional_info_modal.data.addresses).length === 1">   
                            <p class="title">{{$t('matches.swipe.modal.sections.map.title.singular')}}</p>
                            <span class="address">{{ filter_addresses(additional_info_modal.data.addresses)[0] }}</span>

                            <div class="maps-container">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    frameborder="0" style="border:0"
                                    referrerpolicy="no-referrer-when-downgrade"
                                    :src="maps_src(filter_addresses(additional_info_modal.data.addresses)[0])"
                                    allowfullscreen>
                                </iframe>
                            </div>
                            
                        </div>

                        <div class="map" v-else>   
                            <p class="title">{{$t('matches.swipe.modal.sections.map.title.plural')}}</p>
                            <p v-for="(address, index) in filter_addresses(additional_info_modal.data.addresses)" :key="index" class="address">- {{ address }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MatchDetailImInterestedModal       :id="im_interested_modal.data.id"
                                            :matches="im_interested_modal.data.matches"
                                            :workProviderOfferLocations="im_interested_modal.data.workProviderOfferLocations"
                                            :show="im_interested_modal.modal"
                                            type="worker"
                                            source="swipe"
                                            @close="close_modal($event)" />

        <MatchDetailImNotInterestedModal    type="worker"
                                            source="swipe"
                                            :parentMatchId="im_not_interested_modal.data._id"
                                            :show="im_not_interested_modal.modal"
                                            @close="close_modal($event)" />
    </div>
</template>

<script>
import MatchDetailImInterestedModal from '../../../components/Modals/MatchDetail/MatchDetailImInterested.vue';
import MatchDetailImNotInterestedModal from '../../../components/Modals/MatchDetail/MatchDetailImNotInterested.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'WorkerMatchSwipe',

    components: {
        MatchDetailImInterestedModal,
        MatchDetailImNotInterestedModal
    },

    props: {
        matches: {
            type: Array,
            default() {
                return []
            }
        },

        loaded: {
            type: Boolean,
            default() {
                return false
            }
        },
    },

    watch: {
        matches: function() {
            const self = this;
            if(self.matches.length !== 0)
                self.format_matches();
        },

        'additional_info_modal.modal': function() {
            const self = this;
            if(self.additional_info_modal.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    data() {
        return {
            data: [],
            type: 'worker',
            notify: false,
            current_index: 0,

            additional_info_modal: {
                data: null,
                selected_address: null,
                modal: false
            },

            im_not_interested_modal: {
                data: {
                    _id: null
                },
                modal: false
            },

            im_interested_loading: false,
            im_interested_modal: {
                data: {},
                modal: false,
            }
        }
    },

    computed: {
        ...mapGetters([
            'created_at'
        ]),

        new_account: function() {
            const self = this;
            return (Date.now() - self.created_at) <= (5 * 60 * 1000);
        }
    },
    
    methods: {
        get_logo_url: function(match) {
            return match.logo_url ? match.logo_url : 'https://res.cloudinary.com/dicfo8mou/image/upload/v1685812895/likab-platform/clients/logos/aalu2n51ilwwago16j47.png'
        },

        next: function(step) {
            const self = this;
            
            if(self.current_index !== self.data.length) {
                if(step === 'later') { self.update_match_stage(); }
            }
        },

        close_modal: function(payload) {
            const self = this;
            const { next, source } = payload;

            if(source === 'not-interested') { self.im_not_interested_modal.modal = false; }
            else{ self.im_interested_modal.modal = false; }
            
            if(next) {
                const position = self.$screen.width > 768 ? 'bottom-right' : 'top-right';
                self.$toasted.success(
                    self.$t('matches.swipe.modal.not-interested.success'),
                    { icon: 'circle-check', duration: '2000', position }
                );

                if(self.current_index !== self.data.length) {
                    self.current_index += 1;
                }
            }
        },

        im_not_interested: function() {
            const self = this;

            if(self.current_index !== self.data.length) { 
                const match = self.data[self.current_index];
                if(match && match._id) {
                    self.im_not_interested_modal.data = match;
                    self.im_not_interested_modal.modal = true;
                } else {
                    console.log(JSON.stringify({location: 'worker_match_swipe', error: 'can\'t find match on match not interested swipe'}, null, 4))
                    
                    const position = self.$screen.width > 768 ? 'bottom-right' : 'top-right';
                    self.$toasted.error(
                        self.$t('errors.front-end.anErrorOccured'),
                        { icon: 'circle-exclamation', position }
                    );
                }
            }
        },

        im_interested: function() {
            const self = this;
            
            if(self.current_index !== self.data.length) { 
                const match = self.data[self.current_index];
                if(match && match._id) {
                    if(match.addresses.length === 1 && match.matches.length === 1) {
                        self.im_interested_loading = true;

                        const data = {
                            stage: 'interested',
                            selectedMatches: [match.matches[0]._id]
                        }

                        self.$axios
                            .post(`matches/worker/stage`, data)
                            .then(response => {
                                if(response.status == 200) {
                                    self.im_interested_loading = false;
                                    
                                    const position = self.$screen.width > 768 ? 'bottom-right' : 'top-right';
                                    self.$toasted.success(
                                        self.$t('matches.swipe.modal.not-interested.success'),
                                        { icon: 'circle-check', duration: '2000', position }
                                    );

                                    if(self.current_index !== self.data.length) {
                                        self.current_index += 1;
                                    }
                                }
                            })
                            .catch(error => {
                                if(error && error.response) {
                                    if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                                } else { console.log(error); }

                                self.im_interested_loading = false;
                            });
                    }else {
                        self.im_interested_modal.data = {
                            id: match.matches[0]._id,
                            matches: match.matches,
                            addresses: match.addresses,
                            workProviderOfferLocations: match.workProviderOfferLocations
                        }

                        self.im_interested_modal.modal = true;
                    }
                } else {
                    console.log(JSON.stringify({location: 'worker_match_swipe', error: 'can\'t find match on match interested swipe'}, null, 4))
                    const position = self.$screen.width > 768 ? 'bottom-right' : 'top-right';
                    self.$toasted.error(
                        self.$t('errors.front-end.anErrorOccured'),
                        { icon: 'circle-exclamation', position }
                    );
                }
            }
        },

        update_match_stage: function() {
            const self = this;

            const match = self.data[self.current_index];
            if(match && match._id) {
                self.im_interested_loading = true;

                const data = {
                    parentMatchId: match._id,
                    stage: 'seen'
                }
                
                self.$axios
                    .post('match/worker/stage', data)
                    .then(response => {
                        if(response.status == 200){
                            self.im_interested_loading = false;
                            self.current_index += 1;
                        }
                    })
                    .catch(error => {
                        self.im_interested_loading = false;

                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            }
        },

        maps_src: function(address) {
            return `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_EMBEDED_API_KEY}&q=${address}`
        },

        format_matches: function() {
            const self = this;  
            
            self.data = self.matches;
            self.data = self.data.map(match => {
                return {
                    ...match,
                    location: self.compute_location_label(match),
                    distances: match.distances.length === 1 ? `<b>${match.distances[0]}km</b>` : `${self.$t('language.words.between')} <b>${match.distances[0]}km</b> ${self.$t('language.words.and')} <b>${match.distances[1]}km</b>`,
                    matchPercentages: match.matchPercentages.length === 1 ? self.$utils._computePercentageHTML(match.matchPercentages[0]) : `${self.$utils._computePercentageHTML(match.matchPercentages[0])} - ${self.$utils._computePercentageHTML(match.matchPercentages[1])}`,
                    workLocations: match.workLocations.length === 1 ? self.$t(`table.matches.td.workLocations.${match.workLocations[0]}`) : self.$t(`table.matches.td.workLocations.both`),
                    address: self.format_addresses(match.addresses),
                }
            })
        },

        format_days: function(grouped_days) {
            const self = this;
            let outside_string = '';

            for (let i = 0; i < grouped_days.length; i += 1) {
                const days_group = grouped_days[i];

                let inside_string = ''
                for (let j = 0; j < days_group.length; j += 1) {
                    inside_string += self.$t(`workdays.short.${days_group[j]}`)
                }
                
                outside_string += i === grouped_days.length - 1 ? `${inside_string}` : `${inside_string} / `
            }

            return outside_string;
        },

        format_time: function(time) {
            if(time.charAt(0) === '0') {
                return time.substring(1);
            }

            return time;
        },

        filter_addresses: function(addresses) {
            return addresses.filter(address => address !== 'remote');
        },

        compute_added_date: function(match) {
            const self = this;

            if(match.created_since_days === 0) {
                return self.$t('matches.cards.worker_matches.added_date.today');
            }else if(match.created_since_days === 1) {
                return self.$t('matches.cards.worker_matches.added_date.yesterday');
            }else {
                return self.$t('matches.cards.worker_matches.added_date.day', { days: match.created_since_days });
            }
        },

        compute_location_label: function(match) {
            const self = this;

            if(match.workLocations.length === 1 && match.workLocations[0] === 'remote') {
                return self.$t('table.matches.td.workLocations.remote');
            }

            if(match.workLocations.length === 1 && match.workLocations[0] === 'regular') {
                return self.$t('table.matches.td.workLocations.regular');
            }

            if(match.workLocations.length === 2) {
                return self.$t('table.generic.td.locations.multipleAndRemote');
            }
        },

        format_addresses: function(addresses) {
            const self = this;

            if(addresses.length === 1 && !addresses.includes('remote')) {
                return addresses[0];
            }

            if(addresses.length !== 1 && !addresses.includes('remote')) {
                return self.$t('table.generic.td.locations.multiple')
            }

            if(addresses.length !== 1 && addresses.includes('remote')) {
                return self.$t('table.generic.td.locations.multipleAndRemote')
            }
        },

        format_salaries: function(salaries) {
            let result = '';    

            if(salaries) {
                salaries.forEach((salary, index) => {
                    if(salary.type === 'hourly') {
                        if(salaries.length === 1) {
                            result = `${salary.amount.replace('.', ',')} $`
                        } else {
                            if(index === 1) {
                                result = result + ` - ${salary.amount.replace('.', ',')} $`
                            }else {
                                result = result + `${salary.amount.replace('.', ',')} $`
                            }
                        }
                    }else {
                        if(salaries.length === 1) {
                            result = `$ ${salary.amount}`
                        } else {
                            if(index === 1) {
                                result = result + ` -  $ ${salary.amount}`
                            }else {
                                result = result + `$ ${salary.amount}`
                            }
                        }
                    }
                })
            }

            return result;
        },

        format_start_date: function(date) {
            const self = this;

            if(self.$moment(date).isBefore(self.$moment())) {
                return self.$t('matches.swipe.starts-today')
            }

            const m = self.$moment(date);
            m.locale('fr');
            
            return m.format('Do MMM YYYY');
        },

        open_modal: function(data) {
            const self = this;

            self.additional_info_modal = {
                data,
                selected_address: self.filter_addresses(data.addresses)[0],
                modal: true
            }
        }
    },

    mounted() {
        const self = this;

        if(self.matches.length !== 0)
            self.format_matches();
    }
}
</script>

