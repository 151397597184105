<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t('pages.matches.worker.title')}}</h1>
        </div>
        
        <div id="with-sticky-header" class="row with-sticky-header"></div>
        
        <WorkerMatchSwipe :matches="matches" :loaded="loaded" @archived="removeMatchFromMatches($event)" />
    </layout>
</template>

<script>
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import WorkerMatchSwipe from '../../../../components/Cards/Matches/WorkerMatchSwipe.vue';

export default {
    name: 'WorkerMatches',

    components: {
        Layout,
        WorkerMatchSwipe
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },
 
        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'offers',
            classes: { dashboard: true },
            titleKey: '',

            matches: [],
            loaded: false,

            show_scroll_right: false,
            match_types: null,
        }
    },

    methods: {
        get_matches: function() {
            const self = this;
            
            self.$axios
                .get('worker/matches')
                .then(response => {
                    if(response.status == 200) {
                        self.matches = response.data;

                        self.$nextTick(() => {
                            self.loaded = true;
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    self.loaded = true;
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },
        
        removeMatchFromMatches: function(id) {
            const self = this;

            const index = self.matches.map(match => match._id).indexOf(id);
            if(index != -1) {
                self.matches.splice(index, 1);

                self.$toasted.success(
                    self.$t('notifications.matchArchivedSuccess'),
                    { icon: 'circle-check', duration: 5000 }
                );
            }
        }
    },

    mounted() {
        const self = this; 
        
        self.get_matches();
    },

    unmounted() {
        window.removeEventListener('resize');
    }
}
</script>